import React, { useEffect, useContext } from "react";
import {graphql, Link} from "gatsby";
import UKRegions from "../../assets/map/uk-regions/index"
import "../../assets/map/singleRegionMap.scss";
import PageWrapper from "../../components/PageWrapper";
import {Col, Container, Row} from "react-bootstrap";
import {Button, MainTitle, Section, Text, Title} from "../../components/Core";
import styled from "styled-components";
import Image from "gatsby-image";
import Seo from "./../../components/Seo";
import LocalStats from "./LocalStats";
import CheckAvailability from "../../sections/index/CheckAvailability";
import GlobalContext from "../../context/GlobalContext";
import locationImg from "../../assets/image/rebrand/location.svg"
import circleGradientFull from "../../assets/image/rebrand/circle-gradient-full.svg"


const BorderRadiusImage = styled(Image)`
    border-radius: 50%;
    width: 450px !important;
    height: 450px !important;

    @media (max-width: 576px) {
        width: 320px !important;
        height: 320px !important;
        max-width: 100% !important;
    }
`

const CheckAvailabilityBox = styled.div`
    border: 1px solid #c11888;
    border-radius: 20px;
    padding: 50px;
    box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.20);
    margin-top: 50px;
    @media (max-width: 991px) {
        padding: 20px;
    }
`;

const HeroSection = styled(Section)`
    background: url(${circleGradientFull}) no-repeat;
    background-position: -334px 297px;
`

const StrapiTown = (props) => {
    const gContext = useContext(GlobalContext)
    useEffect(() => {
        gContext.goResetRegistrationData()
    }, [])
    
    const City = props.data.strapiTowns

    console.log('city', City)   

    return (
        <>
            <Seo page={City.Slug}/>
            <PageWrapper>
                <HeroSection>
                    <Container>
                        <Row>
                            <Col sm={12} lg={6} className="text-center">
                                <BorderRadiusImage
                                    alt=""
                                    fixed={City.Featured_image?.childImageSharp.fixed}
                                />
                            </Col>
                            <Col sm={12} lg={6}>
                                <MainTitle className="mb-1">Fibre Broadband in {City.Name}</MainTitle>
                                <Link to={`/areas/${City.region.Slug}`}>
                                    <Text className="pb-5" color="black">
                                        <img src={locationImg} width={20} className="mr-2"/>{City.region.Name},
                                        United Kingdom</Text>
                                </Link>
                                {
                                    City.Status && (City.Status === 'live' || City.Status === 'in_progress') &&
                                    <CheckAvailabilityBox>
                                        <Text
                                            color="#000"
                                            className="text-center"
                                        >
                                            Enter your postcode to get<br />
                                            faster fibre today!
                                        </Text>
                                        <CheckAvailability
                                            focusInput={false}
                                            hideLogo={true}
                                            customTitle={""}
                                            customSubtitle={""}
                                        />
                                    </CheckAvailabilityBox>                           
                                }
                            </Col>
                        </Row>
                    </Container>
                </HeroSection>

                <LocalStats
                    Size={City.Size}
                    Investment={City.Investment}
                    Status={City.Status}
                    CityName={City.Name}
                    All={props.data}
                />
            </PageWrapper>
        </>
    );

}

export const data = graphql`
    query getStrapiTownOnSlug($Slug: String) {
      strapiTowns(Slug: {eq: $Slug}) {
        Name
        Region
        Slug
        Size
        Investment
        Status
        Featured_image{
            childImageSharp {
                fixed(width: 500, height: 500) {
                ...GatsbyImageSharpFixed
                }
            }
        }
        region {
          Slug
          Name
          Viewbox
        } 
      }
      strapiRegions(towns: {elemMatch: {Slug: {eq: $Slug}}}) {
        towns {
            Slug
        }
      }
    }`

export default StrapiTown
